import { getAuth, getIdToken } from 'firebase/auth';
import axios, { AxiosInstance } from 'axios';

export default class LedgerApi {
  public readonly http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_HASURA_ENDPOINT + '/v1/graphql',
    });

    this._initialize();
  }

  _initialize() {
    this.http.interceptors.request.use(async (config) => {
      const user = getAuth().currentUser;

      if (!user) {
        return config;
      }

      config['headers'].set('Authorization', `Bearer ${await getIdToken(user)}`);

      return config;
    });
  }

  /**
   * Request to the Hasura GraphQL API
   */
  async request<Request extends Record<string, any>, Response extends Record<string, any>>(
    operationName: string,
    query: string,
    variables?: Request
  ): Promise<Response> {
    const { data } = await this.http.post<Response>(
      '',
      {
        operationName,
        query,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if ('errors' in data) {
      throw new Error(data.errors[0].message);
    }

    return data;
  }

  approveLedger(data: { ledgerId: string; note: string; statusId: string }) {
    return this.request(
      'approveLedger',
      `mutation approveLedger($data: ApproveLedgerInput!) {
        approve_ledger(data: $data) {
          ledgerId
          statusId
        }
      }`,
      {
        data,
      }
    );
  }

  resetToDraft(ledgerId: string) {
    return this.request(
      'resetToDraft',
      `mutation resetToDraft($data: ApproveLedgerInput!) {
        approve_ledger(data: $data) {
          ledgerId
          statusId
        }
      }`,
      {
        data: {
          ledgerId,
          statusId: 'draft',
          note: 'ย้อนสถานะกลับเป็นแบบร่าง',
        },
      }
    );
  }

  submitLedger(data: { ledgerId: string; note: string }) {
    return this.request(
      'submitLedger',
      `mutation submitLedger($data: SubmitLedgerInput!) {
        submit_ledger(data: $data) {
          ledgerId
        }
      }`,
      {
        data,
      }
    );
  }

  submitReceivable(receivableId: string) {
    return this.request(
      'submitReceivable',
      `mutation submitReceivable($data: SubmitReceivableInput!) {
      submit_receivable(data: $data) {
        receivableId
      }
    }`,
      {
        data: {
          receivableId: receivableId,
        },
      }
    );
  }

  deleteReceivable(receivableId: string) {
    return this.request(
      'deleteReceivableById',
      `mutation deleteReceivableById($id: uuid!) {
        delete_ledger_receivable_by_pk(id: $id) {
          id
        }
      }`,
      {
        id: receivableId,
      }
    );
  }

  closeReceivable(data: { date: string; expenseAccountId: string; receivableId: string; refundAmount: number }) {
    return this.request(
      `closeReceivable`,
      `mutation closeReceivable($data: CloseReceivableInput!) {
      close_receivable(data: $data) {
        receivableId
      }
    }`,
      {
        data: data,
      }
    );
  }
}
