import { getAuth, getIdToken, signOut } from 'firebase/auth';
import axios, { AxiosInstance } from 'axios';
import LedgerApi from '@app/libs/ledger';

export class Api {
  public readonly http: AxiosInstance;
  public readonly ledger: LedgerApi = new LedgerApi();

  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    this._initialize();
  }

  _initialize() {
    this.http.interceptors.request.use(async (config) => {
      const user = getAuth().currentUser;

      if (!user) {
        return config;
      }

      config['headers'].set('Authorization', `Bearer ${await getIdToken(user)}`);

      return config;
    });
  }

  async getCurrentUser() {
    const { data } = await this.http.get<User>('/api/v1/user');
    return data;
  }

  async firebaseAuthCallback(firebaseUid: string) {
    const { data } = await this.http.get<User>('/auth/callback', {
      params: {
        firebase_uid: firebaseUid,
      },
    });

    return data;
  }

  logout() {
    return signOut(getAuth());
  }
}

const api = new Api();

export default api;

export type User = {
  id: string;
  displayName: string;
  organizationId: string | null;
  organization: {
    id: string;
    reference: string;
    name: string;
    shortName: string;
  } | null;
  account: {
    id: string;
    email: string;
    defaultRole: string;
    accountRoles: {
      role: string;
    }[];
  } | null;
  firebaseUid: string;
  citizenId: string;
};
