import {
  useGetReceivableByPkQuery,
  useUpdateReceivableByPkMutation,
  ReceivableWithItemsFragment,
  LedgerReceivableSetInput,
  LedgerReceivableStatusEnum,
  LedgerFragment,
  ReceivableLogFragment,
} from '@app/generated/graphql';
import DescriptionIcon from '@material-ui/icons/Description';
import { pageContent } from '@app/shared/hoc/page-content';
import React, { useCallback } from 'react';
import PageTitle from '@app/shared/components/PageTitle';
import BackButton from '@app/shared/components/buttons/BackButton';
import { DataField, FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { FormProvider, useForm } from 'react-hook-form';
import Spacing from '@app/components/common/Spacing';
import { CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import SaveButton from '@app/shared/components/buttons/SaveButton';
import Page from '@app/components/Page';
import { useIfUser } from '@app/components/common/Routes';
import { DateUtils } from '@app/shared/libs/date';
import { ReceivableForm } from './ReceivableForm';
import { useTypography } from '@app/shared/styles/typography';
import { useParams } from 'react-router';
import NotFound from '../NotFound';
import { ReceivableDone, ReceivableSubmit } from './components/ReceivableWorkflow';
import ReceivableStepper from './components/ReceivableStepper';
import DeleteButton from '@app/shared/components/buttons/DeleteButton';
import { useHistory } from 'react-router-dom';
import { ReceivableStatus } from './components/ReceivableStatus';
import { ReceivableLedgers } from './components/ReceivableLedgers';
import ReceivableLogs from './components/ReceivableLogs';
import NumberFormat from 'react-number-format';
import { prompt } from '@app/shared/libs';
import { useMutation } from '@tanstack/react-query';
import api from '@app/libs/api';

const ReceivableDetailForm = ({
  defaultValue,
  ledgers,
  logs,
}: {
  defaultValue: ReceivableWithItemsFragment;
  ledgers: LedgerFragment[];
  logs: ReceivableLogFragment[];
}) => {
  const typographyClasses = useTypography();
  const history = useHistory();
  const form = useForm<ReceivableWithItemsFragment>({
    defaultValues: defaultValue,
  });

  const isSysadmin = useIfUser({ condition: ({ roles }) => roles.includes('sysadmin') });
  const { enqueueSuccess, enqueueFailure } = useResponseBar();
  const [updateFn] = useUpdateReceivableByPkMutation({
    onCompleted: () => {
      enqueueSuccess('บันทึกข้อมูลสำเร็จ');
    },
    onError: (err: any) => {
      console.error(err);
      enqueueFailure('เกิดข้อผิดพลาด' + err);
    },
  });

  const { mutate: deleteFn } = useMutation({
    mutationFn: async () => {
      return api.ledger.deleteReceivable(defaultValue.id);
    },
    onSuccess: () => {
      enqueueSuccess('ลบข้อมูลสำเร็จ');
      history.replace('/bof/accounting/receivables');
    },
    onError: () => {
      enqueueFailure('ไม่สามารถลบข้อมูลได้');
    },
  });

  const handleDelete = useCallback(() => {
    prompt(
      'ท่านแน่ใจที่จะลบข้อมูลใช่หรือไม่?\n\nเป็นการลบข้อมูลออกจากระบบถาวร โดยที่จะไม่สามารถกู้คืนกลับมาได้\n\nโปรดพิมพ์คำว่า "delete" เพื่อยืนยันการลบข้อมูล',
      {
        value: 'delete',
        callback: () => {
          deleteFn();
        },
      }
    );
  }, [deleteFn, defaultValue]);

  const handleSubmit = form.handleSubmit((formData: ReceivableWithItemsFragment) => {
    const fiscalYear = DateUtils.getThaiFiscalYear(formData.date);

    const data: LedgerReceivableSetInput = {
      date: formData.date,
      note: formData.note,
      gfmis_id: formData.gfmis_id,
      fiscal_year: fiscalYear,
      due_date: formData.due_date,
      proposal: formData.proposal,
      borrower_name: formData.borrower_name,
      reference: formData.reference,
      amount: formData.amount,
      budget_id: formData.budget?.id,
    };

    if (isSysadmin) {
      data.organization_id = formData.organization_id;
    }

    updateFn({
      variables: {
        id: defaultValue.id,
        _set: data,
      },
    });
  });

  return (
    <Page
      title={
        <PageTitle
          title={
            <React.Fragment>
              <Typography variant="h5" color="primary" className={typographyClasses.pageTitle}>
                <DescriptionIcon color="primary" fontSize="small" /> ทะเบียนคุมลูกหนี้เงินยืม
              </Typography>
              <ReceivableStatus status={defaultValue.status} />
            </React.Fragment>
          }
          actions={
            <div>
              <BackButton to={`/bof/accounting/receivables`} />
            </div>
          }
        />
      }
    >
      <InfoCard
        actions={
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <DeleteButton onClick={handleDelete} />
            </Grid>
            {defaultValue.status_id === LedgerReceivableStatusEnum.Draft && (
              <Grid item>
                <SaveButton onClick={handleSubmit} />
              </Grid>
            )}
          </Grid>
        }
      >
        <ReceivableStepper status={defaultValue.status_id as any} />
        <Divider />
        <Spacing />
        <Typography variant="h6" color="textSecondary" gutterBottom>
          แบบฟอร์มข้อมูลทะเบียนคุมลูกหนี้เงินยืม
        </Typography>
        <FormProvider {...form}>
          <ReceivableForm mode="update" data={defaultValue} />
        </FormProvider>
        <Spacing />
        {[LedgerReceivableStatusEnum.Draft, LedgerReceivableStatusEnum.Submitted].includes(defaultValue.status_id) && (
          <Grid container justify="center">
            <Grid item>
              <ReceivableSubmit receivable={defaultValue} />
            </Grid>
          </Grid>
        )}

        {LedgerReceivableStatusEnum.Done === defaultValue.status_id && (
          <FormLayout>
            <FormLayoutGroup>
              <Typography variant="h6">ข้อมูลหักล้างเงินยืม</Typography>
            </FormLayoutGroup>
            <FormLayoutGroup>
              <DataField
                label="วันที่ส่งใช้ (เสร็จสิ้น)"
                children={<Typography variant="body1">{DateUtils.toThaiDatetime(defaultValue.done_at)}</Typography>}
              />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <DataField
                label="รหัสและชื่อบัญชีค่าใช้จ่าย"
                children={
                  <Typography variant="body1">
                    {defaultValue.expense_account?.code} - {defaultValue.expense_account?.name}
                  </Typography>
                }
              />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <DataField
                label="ยอดค่าใช้จ่ายจริง"
                children={
                  <Typography variant="body1">
                    <NumberFormat
                      displayType="text"
                      value={defaultValue.amount - defaultValue.refund_amount}
                      thousandSeparator
                      suffix=" บาท"
                    />
                  </Typography>
                }
              />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <DataField
                label="ยอดเงินยืม"
                children={
                  <Typography variant="body1">
                    <NumberFormat displayType="text" value={defaultValue.amount} thousandSeparator suffix=" บาท" />
                  </Typography>
                }
              />
            </FormLayoutGroup>
            <FormLayoutGroup>
              <DataField
                label="ยอดเงินคืน"
                children={
                  <Typography variant="body1">
                    <NumberFormat
                      displayType="text"
                      value={defaultValue.refund_amount}
                      thousandSeparator
                      suffix=" บาท"
                    />
                  </Typography>
                }
              />
            </FormLayoutGroup>
          </FormLayout>
        )}
      </InfoCard>
      <Spacing />
      {[LedgerReceivableStatusEnum.Submitted].includes(defaultValue.status_id) && (
        <InfoCard title="หักล้างหนี้เงินยืม" subheader="รายงานค่าใช้จ่าย, ยอดเงินคืน, บัญชีค่าใช้จ่าย">
          <ReceivableDone receivable={defaultValue} />
        </InfoCard>
      )}
      <Spacing />
      <ReceivableLedgers ledgers={ledgers} />
      <Spacing />
      <ReceivableLogs logs={logs} />
    </Page>
  );
};

const ReceivableDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetReceivableByPkQuery({
    variables: {
      id: id,
    },
  });

  if (loading && !data?.row) {
    return <CircularProgress />;
  }

  if (!data?.row) {
    return <NotFound />;
  }

  return <ReceivableDetailForm defaultValue={data?.row} ledgers={data?.ledgers} logs={data?.logs} />;
};

export default pageContent(ReceivableDetail);
