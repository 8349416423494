import { ErrorMessage, TextField } from '@aginix/mui-react-hook-form-input';
import { LedgerFragment } from '@app/generated/graphql';
import ButtonCircularProgress from '@app/shared/components/ButtonCircularProgress';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { useColors } from '@app/shared/styles';
import { Button, FormControl, Grid } from '@material-ui/core';
import { FormLayout, FormLayoutGroup, InfoCard } from '@mystiny/ui';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { prompt } from '@app/shared/libs';
import { useMutation } from '@tanstack/react-query';
import api from '@app/libs/api';

export interface LedgerWorkflowProps {
  ledger: LedgerFragment;
}

type FormState = {
  comment: string;
};

export const LedgerVerification = ({ ledger }: LedgerWorkflowProps) => {
  const { enqueueFailure, enqueueSuccess } = useResponseBar();
  const [loadingStatus, setLoadingStatus] = useState<string | null>(null);
  const classes = useColors();
  const form = useForm<FormState>({
    defaultValues: {
      comment: '',
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: { statusId: string }) => {
      const response = await api.ledger.approveLedger({
        ledgerId: ledger.id,
        note: form.getValues('comment'),
        statusId: data.statusId,
      });

      return response;
    },
    onSuccess: async () => {
      enqueueSuccess('ปรับสถานะสำเร็จ');
      setLoadingStatus(null);

      window.location.reload();
    },
    onError() {
      enqueueFailure('ไม่สามารถบันทึกข้อมูลได้');
      setLoadingStatus(null);
    },
  });

  const handleApprove = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "อนุมัติ" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          mutate({ statusId: 'approved' });
          setLoadingStatus('approved');
        },
      }
    );
  });

  const handleReject = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "ไม่อนุมัติ" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          mutate({ statusId: 'rejected' });
          setLoadingStatus('rejected');
        },
      }
    );
  });

  const handleChangeRequest = form.handleSubmit((formData: FormState) => {
    prompt(
      'ท่านแน่ใจที่จะปรับสถานะข้อมูล "เปลี่ยนแปลงข้อมูล" ใช่หรือไม่?\nซึ่งข้อมูลจะไม่สามารถถูกแก้ไขหรือย้อนกลับได้\n\nโปรดพิมพ์คำว่า "yes" เพื่อยืนยันการปรับสถานะ',
      {
        value: 'yes',
        callback: () => {
          mutate({
            statusId: 'change_request',
          });
          setLoadingStatus('change_request');
        },
      }
    );
  });

  const loading = isLoading;

  return (
    <InfoCard title="ปรับสถานะข้อมูลค่าใช้จ่าย" subheader='มองเห็นเฉพาะสิทธิ์ "ระดับหัวหน้า"'>
      <FormProvider {...form}>
        <FormLayout>
          <FormLayoutGroup>
            <FormControl fullWidth error={!!form.errors['comment']}>
              <TextField
                name="comment"
                label="หมายเหตุ"
                rows={3}
                variant="outlined"
                rules={{ required: 'กรุณาระบุหมายเหตุ' }}
                multiline
                fullWidth
              />
              <ErrorMessage name="comment" />
            </FormControl>
          </FormLayoutGroup>
          <FormLayoutGroup>
            <Grid container justify="space-between" spacing={1}>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || ledger.status_id === 'rejected'}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.rejected }}
                  onClick={handleReject}
                >
                  ไม่อนุมัติ {loadingStatus === 'rejected' && <ButtonCircularProgress />}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || ['change_request'].includes(ledger.status_id)}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.changeRequest }}
                  onClick={handleChangeRequest}
                >
                  ร้องขอการเปลี่ยนแปลง {loadingStatus === 'change_request' && <ButtonCircularProgress />}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  disabled={loading || ledger.status_id === 'approved'}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  classes={{ containedPrimary: classes.approved }}
                  onClick={handleApprove}
                >
                  อนุมัติ {loadingStatus === 'approved' && <ButtonCircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </FormLayoutGroup>
        </FormLayout>
      </FormProvider>
    </InfoCard>
  );
};
