import { useCurrentUser } from '@app/hooks';
import api from '@app/libs/api';
import { useResponseBar } from '@app/shared/hooks/useResponseBar';
import { Avatar, Box, Button, ListItem, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { useState } from 'react';

const UserMenu = () => {
  const { enqueueSuccess } = useResponseBar();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await api.logout();
    // Hard redirect to first page
    window.location.href = '/';
    enqueueSuccess('ออกจากระบบสำเร็จ');

    handleClose();
  };

  const user = useCurrentUser()!;

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={
          <Avatar
            style={{
              width: 24,
              height: 24,
            }}
          >
            {user.displayName[0]}
          </Avatar>
        }
        size="small"
        style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box>{user.displayName}</Box>
          <Typography component="div" variant="caption">
            {user.account?.defaultRole} {user.organization ? `(${user.organization.shortName})` : null}
          </Typography>
        </Box>
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <ListItem divider>
          <ListItemText primary={user.displayName} secondary={user.account?.email} />
        </ListItem>
        <MenuItem onClick={handleLogout}>ออกจากระบบ</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
