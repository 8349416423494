import { Link, ListItem, ListItemText, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Sidebar, SidebarDivider, SidebarItem, SidebarProps, SidebarSpace, SidebarSpacer } from '@mystiny/ui';
import { FC, Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { IfUser } from '@app/components/common/Routes';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import { UserPermission } from '@app/user-permission';
import { useCurrentUser } from '@app/hooks';

const Active: FC<any> = ({ children }) => <Fragment>{children}</Fragment>;

const BackOfficeSidebar: FC<SidebarProps> = (props) => {
  const gitRevision = process.env.REACT_APP_GIT_REV;
  const { pathname } = useLocation();
  const user = useCurrentUser()!;

  return (
    <Sidebar {...props}>
      <SidebarSpacer />
      <Active to="/bof/">
        <NavLink to="/bof/dashboard/loan-applications">
          <SidebarItem text="รายงานการขอสินเชื่อ" icon={<DashboardIcon />} />
        </NavLink>
      </Active>
      <SidebarSpacer />
      <UserPermission permissionIds={['LOAN_USER']}>
        <a
          href={
            user?.firebaseUid
              ? `${process.env.REACT_APP_PORTAL_V2_ENDPOINT!}/login?uid=${user.id}`
              : process.env.REACT_APP_PORTAL_V2_ENDPOINT!
          }
        >
          <SidebarItem text="คำขอสินเชื่อ" icon={<DescriptionIcon />} />
        </a>
        <SidebarSpacer />
      </UserPermission>

      <UserPermission permissionIds={['ACCOUNTING_ADMIN', 'ACCOUNTING_MANAGER', 'ACCOUNTING_USER']}>
        <SidebarSpacer />
        <Active to="/bof/accounting">
          <SidebarItem
            active={pathname.startsWith('/bof/accounting')}
            open={pathname.startsWith('/bof/accounting')}
            text="ค่าใช้จ่าย"
            icon={<MonetizationOnIcon />}
          >
            <NavLink to="/bof/accounting/ledgers">
              <SidebarItem text="ค่าใช้จ่าย" />
            </NavLink>
            <NavLink to="/bof/accounting/receivables">
              <SidebarItem text="ลูกหนี้เงินยืม" />
            </NavLink>
            {/* <NavLink to="/bof/accounting/logs">
              <SidebarItem text="Log" />
            </NavLink> */}
            <ListItem>
              <ListItemText primary="รายงาน" primaryTypographyProps={{ variant: 'body1', color: 'textSecondary' }} />
            </ListItem>
            <UserPermission permissionIds={['ACCOUNTING_ADMIN']}>
              <NavLink to="/bof/accounting/reports/total-report">
                <SidebarItem text="Total Report" />
              </NavLink>
            </UserPermission>
            <NavLink to="/bof/accounting/reports/matrix-report">
              <SidebarItem text="Matrix Report" />
            </NavLink>
            <IfUser condition={({ roles }) => roles.includes('sysadmin')}>
              <NavLink to="/bof/accounting/reports/matrix-report-2">
                <SidebarItem text="Matrix Report2" />
              </NavLink>
            </IfUser>
            <NavLink to="/bof/accounting/reports/monthly-report">
              <SidebarItem text="Monthly Report" />
            </NavLink>
            <UserPermission permissionIds={['ACCOUNTING_ADMIN']}>
              <ListItem>
                <ListItemText
                  primary="Master Data"
                  primaryTypographyProps={{ variant: 'body1', color: 'textSecondary' }}
                />
              </ListItem>
              <NavLink to="/bof/accounting/accounts">
                <SidebarItem text="ผังบัญชี" />
              </NavLink>
              <NavLink to="/bof/accounting/budget-types">
                <SidebarItem text="งบประมาณ" />
              </NavLink>
              <NavLink to="/bof/accounting/budgets">
                <SidebarItem text="จัดสรรงบประมาณ" />
              </NavLink>
              <a
                href="https://superset.thaismefund.com/superset/dashboard/p/x41r9Wd3aLq/"
                target="_blank"
                rel="noreferrer"
              >
                <SidebarItem text="รายงานสรุปภาพรวม (BI)" />
              </a>
            </UserPermission>
          </SidebarItem>
        </Active>
      </UserPermission>

      <SidebarSpacer />

      <UserPermission permissionIds={['PROJECT_ADMIN', 'PROJECT_MANAGER', 'PROJECT_USER']}>
        <Active to="/bof/projects">
          <SidebarItem
            active={pathname.startsWith('/bof/projects')}
            open={pathname.startsWith('/bof/projects')}
            text="แผนงานและงบประมาณ"
            icon={<AssistantPhotoIcon />}
          >
            <UserPermission permissionIds={['PROJECT_ADMIN']}>
              <NavLink to="/bof/projects/todo">
                <SidebarItem text="Todo List" />
              </NavLink>
            </UserPermission>
            <NavLink to="/bof/projects/proposals">
              <SidebarItem text="คำของบประมาณ" />
            </NavLink>
            <NavLink to="/bof/projects/budgets-comparison">
              <SidebarItem text="รายงานแผน/ผลการใช้งบประมาณ" />
            </NavLink>
            <UserPermission permissionIds={['PROJECT_ADMIN']}>
              <ListItem>
                <ListItemText
                  primary="Master Data"
                  primaryTypographyProps={{ variant: 'body1', color: 'textSecondary' }}
                />
              </ListItem>
              <NavLink to="/bof/projects/master-data/strategy-subject">
                <SidebarItem text="ยุทธศาสตร์แห่งชาติ" />
              </NavLink>
              <NavLink to="/bof/projects/master-data/budgets">
                <SidebarItem text="งบประมาณ (แตกตัวคูณ)" />
              </NavLink>
              <NavLink to="/bof/projects/master-data/budget-groups">
                <SidebarItem text="หมวดค่าใช้จ่ายงบประมาณ" />
              </NavLink>
              <NavLink to="/bof/projects/master-data/units">
                <SidebarItem text="หน่วย (งบประมาณ-แตกตัวคูณ)" />
              </NavLink>
              <NavLink to="/bof/projects/master-data/target-groups">
                <SidebarItem text="กลุ่มเป้าหมาย" />
              </NavLink>
              <a
                href="https://superset.thaismefund.com/superset/dashboard/p/Pmb36zj3xp8/"
                target="_blank"
                rel="noreferrer"
              >
                <SidebarItem text="รายงานสรุปภาพรวม (BI)" />
              </a>
            </UserPermission>
          </SidebarItem>
        </Active>
      </UserPermission>

      <SidebarSpacer />
      <IfUser condition={({ roles }) => roles.includes('sysadmin')}>
        <Active to="/bof/admin">
          <SidebarItem
            active={pathname.startsWith('/bof/admin')}
            open={pathname.startsWith('/bof/admin')}
            text="ผู้ดูแลระบบ"
            icon={<SupervisorAccountIcon />}
          >
            <Active to="/bof/admin/google-analytics">
              <NavLink to="/bof/admin/google-analytics">
                <SidebarItem text="Analytics" icon={<ShowChartIcon />} />
              </NavLink>
            </Active>
            <SidebarSpacer />
            <Active to="/bof/organizations">
              <NavLink to="/bof/organizations">
                <SidebarItem text="หน่วยงาน" icon={<BusinessIcon />} />
              </NavLink>
            </Active>
            <SidebarSpacer />
            <Active to="/bof/programs">
              <NavLink to="/bof/programs">
                <SidebarItem text="โครงการสินเชื่อ" icon={<ListAltIcon />} />
              </NavLink>
            </Active>
            <SidebarSpacer />
            <Active to="/bof/users">
              <NavLink to="/bof/users">
                <SidebarItem text="Users" icon={<PeopleIcon />} />
              </NavLink>
            </Active>
          </SidebarItem>
        </Active>
      </IfUser>
      <SidebarSpacer />
      <SidebarSpacer />
      <SidebarDivider />
      <SidebarSpacer />
      <SidebarSpacer />
      <Link href="/handout.pdf" target="_blank">
        <SidebarItem text="คู่มือการใช้งานระบบ" icon={<AssignmentIcon />} />
      </Link>
      <SidebarSpacer />
      <Link href="/business-process.png" target="_blank">
        <SidebarItem text="Business Process" icon={<AssignmentIcon />} />
      </Link>
      <SidebarSpace />
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="caption" align="center" component="div">
              Revision: {gitRevision}
            </Typography>
          }
        />
      </ListItem>
    </Sidebar>
  );
};

export default BackOfficeSidebar;
